<template>
   <div id='myroom' style="overflow:hidden;background-color: #f0f0f0;min-width: 1500px;" class="my-room">
        <div style="width: 1500px;margin:108px auto; display: flex;">
            <div class="page_content" style="width:1206px;height: auto;flex: 1; margin-left: 294px; min-height: 1000px;">
				<div class="myroom_tab hb">
					<div class="hs">
						<div class="hand op tab" style="width: 80px" :class="{'active':roomTab == 0}" @click="setRoomTab(0)">
							全部
							<div class="tabs" style="width: 30px" v-if="roomTab == 0"></div>
						</div>
						<div class="hand op tab" :class="{'active':roomTab == 1}" @click="setRoomTab(1)">
							我创建的room
							<div  class="tabs" v-if="roomTab == 1" ></div>
						</div>
						<div class="hand op tab" :class="{'active':roomTab == 2}" @click="setRoomTab(2)">
							我管理的room
							<div  class="tabs" v-if="roomTab == 2" ></div>
						</div>
						<div class="hand op tab" :class="{'active':roomTab == 3}" @click="setRoomTab(3)">
							我参加的room
							<div  class="tabs" v-if="roomTab == 3" ></div>
						</div>
						<div class="hand op tab" :class="{'active':roomTab == 5}" @click="setRoomTab(5)">
							我关注的room
							<div class="tabs" v-if="roomTab == 5"></div>
						</div>
						<div class="hand op tab" :class="{'active':roomTab == 6}" @click="setRoomTab(6)">
							沉睡的room
							<div class="tabs" style="width: 90px" v-if="roomTab == 6"></div>
						</div>
					</div>
					<div>
            <el-autocomplete
                style="display:inline"
                value-key="name"
                v-model="searchContent"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入room名称"
                @select="SelectRoom"
                :highlight-first-item="true"
                :trigger-on-focus="false"
                :clearable = "true"
                @clear="clearSearch"
            ></el-autocomplete>
					</div>
				</div>
        <div style="display: flex;justify-content: flex-start;align-items: center;flex-wrap: wrap;padding-left: 15px;background-color: #ffffff;padding-top: 15px;">
					<NoData detail="暂无此类Room" style="width: 100%;text-align: center" v-if="myShowRoomList.length == 0"></NoData>
					<div v-for="(item, index) in myShowRoomList" :key="index" style="width:calc((100% - 75px) / 5);margin-bottom:17px;margin-right:15px;">
						<NewRoom :data="item"></NewRoom>
					</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import Head from "../../components/common/Head";
	import RoomNav from './components/RoomNav'
	import NewRoom from './components/NewRoom'
	import NoData from "../../components/common/NoData";
	export default {
		name:"MyRoom",
		components: {
			NoData,
			Head,
			RoomNav,
			NewRoom
		},
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            }
        },
		data () {
			return {
				roomName:"",
				roomTab:0,
        searchContent: '',
				myShowRoomList: [],

			}
		},
		props:{

		},
		created:function(){

		},
		mounted: function () {
			this.backTop();
			this.setRoomTab(0)
		},
		methods:{
			backTop () {
				document.documentElement.scrollTop = document.body.scrollTop = 0;
			},
			setRoomTab(type){
				this.roomTab = type;
				this.myShowRoomList = [];
				this.newApi.getMyDutyRoomList({type: this.roomTab, roomType: 0}).then(ret =>{
					this.myShowRoomList = ret.data;
					// that.screen_room_list(that.roomType);
				})
			},

			getRoom() {
				this.newApi.getMyDutyRoomList({type: this.roomTab, roomType: 0}).then(ret =>{
					this.myShowRoomList = ret.data;
				})
			},

      //搜索room
      querySearchAsync(searchContent, cb) {
        this.newApi.getMyDutyRoomList({name:searchContent,roomType:0,type: this.roomTab}).then(res=>{
          cb(res.data);
        })

      },
      SelectRoom(item){
        this.myShowRoomList = [item]
      },

      //  清除后搜索
      clearSearch() {
        this.searchContent = '';
        this.getRoom()
      },
		}
	}

</script>

<style scoped>

.my-room .myroom_tab{
	height: 60px;
	width: 100%;
	padding: 0 44px;
	box-sizing: border-box;
	background: #ffffff;
	box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
	border-radius: 5px 5px 0 0;
	margin-bottom: 10px;
}
.my-room .tab{
  width: 140px;
  margin-right: 10px;
	font-size: 16px;
	color: #555555;
	position: relative;
	height: 60px;
	line-height: 60px;
}
.my-room .tab.active{
	color: #2970FF;
	font-weight: 600;
}
.my-room .tabs{
  width: 110px;
	height: 7px;
	background: #FF9800;
	border-radius: 20px 20px 20px 20px;
	position: absolute;
	bottom: 0;
}
.my-room .hb{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.my-room .hs{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.my-room .search .el-input-group__append{
    background-color:#2970FF;
    color: #ffffff;
}
</style>
